import { ref, Ref } from 'vue';
import { defineStore } from 'pinia';
import { ifCNLocale } from '../composables/useLocale';
import { matchMobile } from '../composables/useMatchRules';
interface State {
    globalWindowHeight: number;
    globalWindowWidth: number;
    isMobile: boolean;
    isCN: boolean;
    isMobileMenuOpen: boolean;
}

export const useWindowStore = defineStore('window', {
    state: (): State => ({
        globalWindowHeight: 0,
        globalWindowWidth: 0,
        isMobile: false,
        isCN: false,
        isMobileMenuOpen: false,
    }),
    getters: {
        getWidth: (state): number => (state.globalWindowWidth > 1440 ? 1440 : state.globalWindowWidth),
        getHeight: (state): number => state.globalWindowHeight,
        getIsMobile: (state): Ref<boolean> => ref(state.isMobile),
        getIsCN: (state): Ref<boolean> => ref(state.isCN),
        getIsMobileMenuOpen: (state): Ref<boolean> => ref(state.isMobileMenuOpen),
    },
    actions: {
        updateWindowState(width: number, height: number) {
            this.globalWindowWidth = width;
            this.globalWindowHeight = height;
        },
        updatedIsMobile() {
            this.isMobile = matchMobile();
        },
        updateIsCN() {
            this.isCN = ifCNLocale();
        },
        updateIsMobileMenuOpen(open: boolean) {
            this.isMobileMenuOpen = open;
            console.log('updateIsMobileMenuOpen', open);
        },
    },
});
