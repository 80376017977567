export const appBuildAssetsDir = "/assets/"

export const appCdnURL = "https://file.linctex.com/homesite/pro/static/"

export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no,viewport-fit=cover"},{"name":"google-site-verification","content":"TAdDZjCfuXBUoA3WhS5lf74sHu4EGOHbp9m4yEoxc2Q"}],"link":[{"rel":"icon","type":"image/x-ico","href":"/logo.png"}],"style":[],"script":[{"innerHTML":"\n                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n                    })(window,document,'script','dataLayer','GTM-WL9RHQ94');\n                    "},{"innerHTML":"var _hmt = _hmt || [];\n                (function() {\n                  var hm = document.createElement(\"script\");\n                  hm.src = \"https://hm.baidu.com/hm.js?8df26a1012715622af6126c6d9374205\";\n                  var s = document.getElementsByTagName(\"script\")[0];\n                  s.parentNode.insertBefore(hm, s);\n                })();"},{"src":"//js-eu1.hs-scripts.com/139816465.js","async":true,"defer":true,"type":"text/javascript"}],"noscript":[],"title":"Style3D","htmlAttrs":{"class":"theme-default"}}

export const appBaseURL = "/"

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"