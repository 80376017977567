import { seoEnConfig, seoZhConfig, seoRouteName } from '~~/configs/seoConfig';
interface IHeadConfig {
    title?: string;
    meta: Array<{ name: string; content: string }>;
}

/**
 * 目标：谷歌 英文SEO，百度 中文SEO
 * 推荐采用 userAgent 识别爬虫来实现SEO
 */
export const useSeo = () => {
    // 目前Google、百度都收录的是中文， 因此默认英文， 方便查找错误，且保证历史正确性
    let seoConfig = seoEnConfig;

    // let userAgent = useRequestHeaders()['user-agent'];
    // console.log('userAgent xx', userAgent);
    // let isSpider = false;
    // if (userAgent) {
    //     userAgent = userAgent.toLowerCase();
    //     if (userAgent.includes('googlebot')) {
    //         seoConfig = seoEnConfig;
    //         isSpider = true;
    //     } else if (userAgent.includes('baiduspider')) {
    //         seoConfig = seoZhConfig;
    //         isSpider = true;
    //     }
    // }
    // const runtimeConfig = useRuntimeConfig();
    // const { SITE } = runtimeConfig.public;
    // if (!isSpider) {
    //     seoConfig = SITE === 'abroad' ? seoEnConfig : seoZhConfig;
    // }

    const route = useRoute();

    let originConfig = seoConfig[(route.name as seoRouteName) || 'index'];
    if (!originConfig) originConfig = seoConfig.index;
    if (!originConfig) return;

    const headConfig: IHeadConfig = { title: 'Style3D', meta: [] };
    if (originConfig.title) headConfig.title = originConfig.title;
    if (originConfig.keywords) headConfig.meta.push({ name: 'keywords', content: originConfig.keywords });
    if (originConfig.description) headConfig.meta.push({ name: 'description', content: originConfig.description });
    // headConfig.meta.push({ name: 'spider', content: `spider: ${isSpider}` });
    // headConfig.meta.push({ name: 'abroad', content: `abroad: ${SITE === 'abroad'}` });

    useHead(headConfig);
};
