<template>
    <Transition name="fade">
        <div v-show="visible" class="floating-button-container">
            <div v-for="(item, index) in config" :key="index" class="floating-button" @click="item.handleClick">
                <!-- <CommonComponentsSVGHeaderMail @click="item.handleClick" /> -->
                <Component
                    class="icon"
                    :is="item.iconComponent"
                    @mouseenter="item.hovered = true"
                    @mouseleave="item.hovered = false"
                />
                <div class="floating-text">{{ item.name }}</div>
            </div>
        </div>
    </Transition>
</template>

<script lang="ts" setup>
    import { onMounted, ref, reactive, resolveComponent, shallowRef } from 'vue';
    import { matchMobile } from '~~/composables/useMatchRules.ts';
    import i18n from '@/locales';
    import { useWindowStore } from '~~/store/windowStore';

    const store = useWindowStore();
    const { t } = i18n.global;
    const visible = ref(false);

    const setVisible = (value: boolean) => {
        visible.value = value;
    };

    const { $bus } = useNuxtApp();
    const showModal = () => {
        if (ifMatched.value) {
            // 移动 端，展开Header
            $bus.$emit('show:mobileNav');
        } else {
            // PC 端，打开弹窗
            $bus.$emit('show:applyModal');
        }
    };

    const config = reactive([
        {
            iconComponent: shallowRef(resolveComponent('CommonComponentsSVGHeaderMail')),
            name: t('global_link_button_1'),
            handleClick: showModal,
            hovered: false,
        },
    ]);

    const ifMatched = ref(false);

    onMounted(() => {
        ifMatched.value = matchMobile();

        // 监听事件
        $bus.$on('show:mobileNav', () => {
            setVisible(false);
        });
        $bus.$on('close:mobileNav', () => {
            setVisible(true);
        });

        $bus.$on('show:floatingButton', () => {
            setVisible(false);
        });
        $bus.$on('hide:floatingButton', () => {
            setVisible(false);
        });
    });
</script>

<style lang="less" scoped>
    @header-mobile-width: 900px;
    .floating-button-container {
        position: fixed;
        top: 15px;
        // transform: translateY(-50%);
        right: 40px;
        z-index: 1000;
        display: flex;
        gap: 10px;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media (max-width: @header-mobile-width) {
            top: 50%;
            transform: translateY(-50%);
            right: 0px;
        }

        .floating-button {
            cursor: pointer;
            width: .convert(126px) [ @vw];
            height: .convert(65px) [ @vh];
            padding: .convert(20px) [ @vw];
            flex-shrink: 0;
            border-radius: 45px;
            display: flex;
            background: #ffffff;
            gap: 4px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            box-shadow: 0px 12px 30px 0px rgba(0, 0, 0, 0.1);
            @media (max-width: @header-mobile-width) {
                background: #0032ff !important;
                height: auto;
                width: 60px;
                padding: 12px 6px;
                border-radius: 10px 0px 0px 10px;
                opacity: 0.8;
                box-shadow: unset;
            }
            .icon {
                display: none;
                @media (max-width: @header-mobile-width) {
                    display: block;
                }
            }

            .floating-text {
                color: #000;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 17px; /* 141.667% */
                text-align: center;
                @media (max-width: @header-mobile-width) {
                    color: #fff;
                    font-size: 12px;
                }
            }
        }
    }
    :lang(en) {
        .floating-button {
            width: auto;
            @media (max-width: @header-mobile-width) {
                width: 60px;
            }
        }
    }
</style>
